:root {

  /* font family */
  --primary-font: 'Roboto', sans-serif;

  /* font size css */
  --h1-font-size: 60px;
  --h2-font-size: 48px;
  --h3-font-size: 40px;
  --h4-font-size: 28px;
  --h5-font-size: 24px;
  --h6-font-size: 22px;

  --p1-font-size: 18px;
  --p2-font-size: 12px;
  --p3-font-size: 14px;

  --tag-font-size: 14px;

  --h1-line-height: 1;
  --h2-line-height: 1.12;
  --h3-line-height: 1.05;
  --h4-line-height: 1.1;
  --h5-line-height: 32px;
  --h6-line-height: 1;

  --p1-line-height: 1.4;
  --p2-line-height: 1.35;
  --p3-line-height: 1.4;

  --tag-line-height: 19px;

  /* color code */
  --primary-color: #1890ff;
  --secondary-color : #fff;
  --tertiary-color: #EEF067;
  
  --primary-color__border : #1890ff;
  --secondary-color__border : #000;
  --tertiary-color__border : #EEF067;

  --primary-color__hover: #116abe;
  --secondary-color__hover: #000;
  --ternary-color__hover: #eef06799;

  --primary-color__disable:  #00000033;
  --secondary-color__disable: #fff;
  --ternary-color__disable: #eef06733;

  --black-color: #000;
  --white-color: #fff;
  --pink-color: #ff4a88;
  --red-color: #FF0000;
  --green-color: #52c41a;
  --lemon-color: #EEF067;
  --storm-color: #D3D3D3;
  --lavender-color: #9FAACD;
  --mint-color: #B7E5AC;
  --peach-color: #EFA580;
  --pear-color: #B6C668;
  --sea-cliff-color: #A5C1C6;
  --cerulean-color: #1591E2;
  --wine-color: #3D000D;
  --forest-color: #023340;
  --sungold-color: #F2BD57;
}


@media only screen and (max-width: 600px){
  :root{
    /* font size css */
    --h1-font-size: 48px;
    --h2-font-size: 40px;
    --h3-font-size: 24px;
  
    --p1-font-size: 20px;
    --p2-font-size: 18px;
    

    --tag-font-size: 18px;  
  }
}