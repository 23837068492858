.voice-call-wrapper{
    position: absolute;
    bottom: 2%;
    background: white;
    right: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    min-height: 420px;
    text-align: center;
    font-family: var( --primary-font);
    box-shadow: 8px 8px 18px 12px rgba(0, 0, 0, .2);
    z-index: 9999;
}

.close-minimize-wrapper{
    position: absolute;
    right: 10%;
    top: 5%;
}

.voice-call-wrapper.minimize{
    min-height: auto;
    padding: 30px 20px;
    min-width: 300px;
    justify-content: space-between;
}

.voice-call-wrapper.minimize .close-minimize-wrapper{
    right: 5%;
}

.voice-call-wrapper .call-txt{
    font-size: var(--p2-font-size);
    padding-bottom: 10px;
    font-weight: 500;
    color: #1b1b1b;
}

.voice-call-wrapper .call-status{
    font-size: var(--p3-font-size);
    margin-bottom: 10px;
    font-weight: 500;
    color: #1b1b1b;
    opacity: 0.8;
}

.avatar-icon{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black-color);
    margin: 0 auto;
}

.call-action-btn{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--storm-color);
    cursor: pointer;
}

.call-action-btn.active{
    background-color: var(--black-color);
}

.end-call-btn{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--red-color);
    cursor: pointer;
}