/* .isoImgWrapper img {
    border-radius: 50%;
    border:1px solid white;
} */

.link {
    align-self: center;
    color: white;
    margin-right: 10px;
    padding: 10px;
}

.Profile-link{
    color: white;
    font-size: 14px;
    padding: 5px 10px 10px 20px;
}


@media (min-width: 320px) and (max-width: 480px){
  .isoCenter{
      padding: 3px 0 0 0 !important;
  }

  .Profile-link{
    color: white;
    font-size: 14px;
    padding: 6;
    line-height: 25px;
  }

  ul.isoRight{
    margin:0 !important;
    padding: 0 !important;
  }

}

@media (min-width: 768px) and (max-width: 1536px) {
  
    ul.isoRight{
        margin:0 !important;
        padding: 0 !important;
    }
    
    .Profile-link{
        padding-top: 9px !important;
    }

}

@media only screen and (max-width: 1225px) {
    .link {
        margin-right: 5px !important;
    }
}

.artDesign {
    color: white;
}

.mobile-menu__drawer .ant-drawer-body {
    padding: 0px;
}

.mobile-menu__drawer .ant-menu {
    margin-top: 50px;
}

.mobile-menu__drawer .ant-menu-vertical {
    border: none !important;
}

.mobile-menu__drawer .ant-menu li {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
}

.topbar-drawer-Style .ant-drawer-body{
    padding: 0px !important;
}

.isoDropdownLink {
    font-size: 13px;
    line-height: 19px;
    display: block;
    padding: 8px 16px;
    color: #000;
    transition: all linear 0.3s;
    border-bottom: 1px solid #ddd;
}

.isoDropdownLink:hover {
    color: #1677ff;
    background: #f0f0f0;
}

.header-call-icon{
    width: 25px;
    height: 25px;
    background-color: var(--green-color);
    border-radius: 100px;
}