@import url("./variables.css");
@import url("./btnStyle.css");

::-webkit-scrollbar {
    width: 10px;
}
  
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: rgb(167, 167, 167);
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

*, body, html {
    margin: 0px;
}

a{
    transition: all linear 0.3s;
}

.primary-light-font{
    font-family: var(--primary-font);
    font-weight: 400;
}

.primary-medium-font{
    font-family: var(--primary-font);
    font-weight: 500;
}

.primary-semibold-font{
    font-family: var(--primary-font);
    font-weight: 600;
}

.primary-bold-font{
    font-family: var(--primary-font);
    font-weight: 900;
}

.h1-heading{
    font-family: var(--primary-font);
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
    font-weight: 600;
    color: var(--black-color);
}

.h2-heading{
    font-family: var(--primary-font);
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
    font-weight: 600;
    color: var(--black-color);
}

.h3-heading{
    font-family: var(--primary-font);
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
    font-weight: 600;
    color: var(--black-color);
}

.h4-heading{
    font-family: var(--primary-font);
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
    font-weight: 600;
    color: var(--black-color);
}

.h5-heading{
    font-family: var(--primary-font);
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
    font-weight: 600;
    color: var(--black-color);
}

.h6-heading{
    font-family: var(--primary-font);
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
    font-weight: 600;
    color: var(--black-color);
}

.p-l-txt{
    font-size: var(--p1-font-size);
    line-height: var(--p1-line-height);
    font-weight: 500;
    color: var(--black-color);
}

.p-m-txt{
    font-size: var(--p2-font-size);
    line-height: var(--p2-line-height);
    font-weight: 500;
    color: var(--black-color);
}

.p-s-txt{
    font-size: var(--p3-font-size);
    line-height: var(--p3-line-height);
    font-weight: 500;
    color: var(--black-color);
}
.p-ts-txt{
    font-size: 12px;
    line-height: var(--p3-line-height);
    font-weight: 500;
    color: var(--black-color);
}

.tag-txt{
    font-size: var(--tag-font-size);
    line-height: var(--tag-line-height);
    font-weight: 500;
    color: var(--black-color);
}

.txt-light{
    font-weight: 400;
}

.txt-regular{
    font-weight: 500;
}

.txt-semiBold{
    font-family: var(--primary-font) !important;
    font-weight: 600;
}

.txt-bold{
    font-family: var(--primary-font) !important;
    font-weight: 900;
}

.font-style-capitalize{
    text-transform: capitalize;
}

.font-style-uppercase{
    text-transform: uppercase;
}

.font-style-lowecase{
    text-transform: lowercase;
}

.font-style-italic{
    font-style: italic;
}

.small-txt-spacing{
    letter-spacing: 0.05em;
}

.txt-opacity-10{
    opacity: 1;
}

.txt-opacity-6{
    opacity: 0.6;
}

.txt-opacity-8{
    opacity: 0.8;
}

.text-decoration-underline {
    text-decoration: underline;
}

.cursor-pointer {
    cursor: pointer;
}

.black-text-color{
    color: var(--black-color);
}

.white-text-color{
    color: var(--white-color) !important;
}

.pink-text-color{
    color: var(--pink-color);
}

.red-text-color{
    color: var(--red-color);
}

.storm-text-color{
    color: var(--storm-color);
}

.pink-text-color{
    color: var(--pink-color);
}

.red-text-color{
    color: var(--red-color);
}

.green-text-color{
    color: var(--green-color);
}
.blue-text-color{
    color: blue;
}

.lemon-text-color{
    color: var(--lemon-color);
}

.lavender-text-color{
    color: var(--lavender-color);
}

.mint-text-color{
    color: var(--mint-color);
}

.peach-text-color{
    color: var(--peach-color);
}

.pear-text-color{
    color: var(--pear-color);
}

.sea-cliff-text-color{
    color: var(--sea-cliff-color);
}

.cerulean-text-color{
    color: var(--cerulean-color);
}

.wine-text-color{
    color: var(--wine-color);
}

.forest-text-color{
    color: var(--forest-color);
}

.sungold-text-color{
    color: var(--sungold-color);
}

.white-bg{
    background : var(--white-color);
}

.black-bg{
    background: var(--black-color);
}

.transparent-bg {
    background: transparent !important;
}

.pink-bg{
    background: var(--pink-color);
}

.red-bg{
    background: var(--red-color);
}

.green-bg{
    background: var(--green-color);
}

.lemon-bg{
    background: var(--lemon-color);
}

.storm-bg{
    background: var(--storm-color);
}

.lavender-bg{
    background: var(--lavender-color);
}

.mint-bg{
    background: var(--mint-color);
}

.peach-bg{
    background: var(--peach-color);
}

.pear-bg{
    background: var(--pear-color);
}

.sea-cliff-bg{
    background: var(--sea-cliff-color);
}

.cerulean-bg{
    background: var(--cerulean-color);
}

.wine-bg{
    background: var(--wine-color);
}

.forest-bg{
    background: var(--forest-color);
}

.sungold-bg{
    background: var(--sungold-color);
}

.custom-display-flex{
    display: flex;
}
.custom-display-none{
    display: none
}

.custom-flex-equal{
    flex: 1;
}

.custom-flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-wrap{
    flex-wrap: wrap;
}

.custom-flex-column{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.custom-flex-column-ml-20{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}

.custom-flex-align-start{
    align-items: flex-start;
}

.custom-flex-align-end{
    align-items: flex-end;
}

.custom-flex-center{
    justify-content: center;
}

.custom-flex-space-between{
    justify-content: space-between;
}

.justify-content-start{
    justify-content: flex-start;
}

.justify-content-center{
    justify-content: center;
}

.justify-content-end{
    justify-content: flex-end;
}

.column-gap-10{
    column-gap: 10px;
}

.column-gap-20{
    column-gap: 20px;
}

.column-gap-30{
    column-gap: 30px;
}

.column-gap-40{
    column-gap: 40px;
}

.column-gap-50{
    column-gap: 50px;
}

.row-gap-10{
    row-gap: 10px;
}

.row-gap-20{
    row-gap: 20px;
}

.row-gap-30{
    row-gap: 30px;
}

.row-gap-40{
    row-gap: 40px;
}

.row-gap-50{
    row-gap: 50px;
}

.text-align-center{
    text-align: center;
}

.text-align-left{
    text-align: left;
}

.text-align-right{
    text-align: right;
}

.custom-image-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.custom-image-max-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-height: 400px;
} 

.custom-image-wrap svg, .custom-image-max-wrap svg{
    width: 100%;
    height: 100%;
}

.custom-image-max{
    height: 100%;
    width: 100%;
}

.custom-image-width{
    width: 100%;
    height: auto;
}

.custom-image-height{
    height: 100%;
    width: auto;
}

.custom-container {
    display: block;
    margin: 57.5px auto;
    padding: 0px 17px;
}
.custom-text-assign-right {
    text-align: right;
}
  

@media (min-width: 768px) {
    .custom-container {
        width: 767px;
        margin: 57.5px auto;
        padding: 0px 17px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        width: 1008px;
        margin: 89px auto;
        padding: 0px 17px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        width: 1208px;
        margin: 89px auto;
        padding: 0px;
    }
}
  
.custom-container__no-right-margin{
    margin-right: 0px;
}

.custom-container-extra-padding{
    padding: 80px 0px 100px 0px;
}

.custom-container-fluid{
    padding: 50px 100px;
    margin: 50px 0px;
}

.container-header-wrap{
    width: 80%;
    margin: 0px auto;
    text-align: center;
    padding-bottom: 50px;
}

.custom-division-gap{
    margin-bottom: 100px !important;
}

.required-label{
    display: inline-block;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.font-size-11{
    display: inline-block;
    font-size: 11px;
}

.mb-0{
    margin-bottom: 0px !important;
}

.mb-5{
    margin-bottom: 5px !important;
}

.mb-10{
    margin-bottom: 10px !important;
}

.mb-20{
    margin-bottom: 20px !important;
}

.mb-30{
    margin-bottom: 30px !important;
}

.mb-40{
    margin-bottom: 40px !important;
}

.mb-50{
    margin-bottom: 50px !important;
}

.mb-60{
    margin-bottom: 50px !important;
}

.mt-0{
    margin-top: 0px !important;
}

.mt-5{
    margin-top: 5px !important;
}

.mt-10{
    margin-top: 10px !important;
}

.mt-20{
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}
  
.mt-80 {
    margin-top: 80px;
}
  
.mt-150 {
    margin-top: 150px;
}

.mr-0{
    margin-right: 0px !important;
}
.mr-2{
    margin-right: 2px !important;
}

.mr-5{
    margin-right: 5px !important;
}
.mr-10{
    margin-right: 10px !important;
}

.mr-20{
    margin-right: 20px !important;
}

.mr-30{
    margin-right: 30px !important;
}

.mr-40{
    margin-right: 40px !important;
}

.mr-50{
    margin-right: 50px !important;
}

.ml-0{
    margin-left: 0px !important;
}

.ml-5{
    margin-left: 5px !important;
}

.ml-10{
    margin-left: 10px !important;
}

.ml-20{
    margin-left: 20px !important;
}

.ml-30{
    margin-left: 30px !important;
}

.ml-40{
    margin-left: 40px !important;
}

.ml-50{
    margin-left: 50px !important;
}


.pt-0{
    padding-top: 0px !important;
}

.pt-5{
    padding-top: 5px !important;
}
.pt-10{
    padding-top: 10px !important;
}

.pt-20{
    padding-top: 20px !important;
}

.pt-30{
    padding-top: 30px !important;
}

.pt-40{
    padding-top: 40px !important;
}

.pt-50{
    padding-top: 50px !important;
}

.pb-0{
    padding-bottom: 0px !important;
}

.pb-10{
    padding-bottom: 10px !important;
}

.pb-20{
    padding-bottom: 20px !important;
}

.pb-30{
    padding-bottom: 30px !important;
}

.pb-40{
    padding-bottom: 40px !important;
}

.pb-50{
    padding-bottom: 50px !important;
}

.pr-0{
    padding-right: 0px !important;
}

.pr-10{
    padding-right: 10px !important;
}

.pr-20{
    padding-right: 20px !important;
}

.pr-30{
    padding-right: 30px !important;
}

.pr-40{
    padding-right: 40px !important;
}

.pr-50{
    padding-right: 50px !important;
}

.pl-0{
    padding-left: 0px !important;
}

.pl-10{
    padding-left: 10px !important;
}

.pl-20{
    padding-left: 20px !important;
}

.pl-30{
    padding-left: 30px !important;
}

.pl-40{
    padding-left: 40px !important;
}

.pl-50{
    padding-left: 50px !important;
}

.txt-width-50{
    width: 50%;
    margin: 0px 25% 0px 25%;
}

.txt-width-80{
    width: 80%;
    margin: 0px 10% 0px 10%;
}

.txt-width-90{
    width: 90%;
    margin: 0px 5% 0px 5%;
}

.w-100{
    width: 100%;
}

.h-100{
    height: 100%;
}

.custom-img-w-100-h-100{
    height: 100%;
    width: 100%;
}

.page-center-container{
    height: calc(100vh - 65px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.custom-input-style{
    height: 45px;
    border-radius: 0px;
}

.custom-radio-group{
    width: 100%;
}

.custom-radio-group .ant-radio-wrapper{
    font-size: var(--p3-font-size);
    line-height: var(--p3-line-height);
    color: var(--black-color);
}

.custom-radio-group.custom-radio-group--two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
}

.custom-select-style{
    width: 100% !important;
}

.custom-select-style.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none !important;
    border-bottom: 1px solid #00000066 !important;
}

.custom-select-style .ant-select-selector{
    border: none !important;
}

.custom-select-style.ant-select, .custom-select-style .ant-select-item{
    font-size: var(--p3-font-size);
    line-height: var(--p3-line-height);
    color: var(--black-color);
}

.custom-popover-item__wrap {
    display: flex;
    flex-direction: column;
}
.custom-popover-item__wrap > div {
    transition: 0.3s all linear;
    padding: 10px;
}

.custom-popover-item__wrap > div:hover {
    background: #e6f4ff;
}
/* 
.custom-popover-item__wrap > div:not(:last-child) {
    margin-bottom: 10px;
} */

.custom-popover-overlay .ant-popover-inner {
    padding: 0px;
}

.text-capitalize{
    text-transform: capitalize;
}

@media only screen and (max-width: 992px){

    .custom-container-fluid{
        padding: 50px;
    }

    .container-header-wrap{
        width: 90%;
    }

    .custom-container.custom-container-sm-margin-bottom{
        margin-bottom: 81px;
    }
}

@media only screen and (max-width: 768px){
    
}

@media only screen and (max-width: 600px){

    .custom-container-fluid{
        padding: 20px;
    }

    .container-header-wrap{
        width: 100%;
    }

    .txt-width-80, .txt-width-90, .txt-width-50{
        width: 100%;
        margin: 0px;
    }

    .custom-flex-row--sm-col{
        flex-direction: column;
    }
}
.old_notes{
    border: 1px solid grey;
    border-radius: 4px;
}
.old_notes_content{
    padding: 5px;
}

.box-ui-badge.orange {
    background: rgb(252, 140, 4);
    border-radius: 7.5px;
    color: var(--black-color);
    width: max-content;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    padding: 2px 10px;
  }