.upload-image-container {
    /* position: absolute;
    top: -120px;
    height: 110px; */
    background: #fff;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px 5px;
    padding: 5px;
  }
  
  .upload-image-container:empty {
    background: transparent;
    display: none;
  }
  
  .upload-image-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  .upload-image-container::-webkit-scrollbar-thumb {
    background: rgb(167, 167, 167);
  }

/*** attachment action wrap ***/

.attachment-action__container .s3-file-remove-btn{
    height: 20px !important;
    width: 20px !important;
    padding: 0px !important;
}

.attachment-action__project-btn {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 69px;
    width: 106px;
    background: var(--storm-color);
    border: 1px dashed rgba(0, 0, 0, 0.5);
    border-radius: 7.5px;
    overflow: hidden;
    cursor: pointer;
}

.attachment-action__project-btn svg {
    height: 20px;
    width: 20px;
}

.custom-image-uploader {
    height: 200px;
    border: 2px dashed #aaaaaa;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
}

.custom-image-uploader p {
    margin: 10 10;
    text-align: center;
}

.custom-image-uploader:hover {
    border-color: #555555;
    background-color: #f9f9f9;
}

.s3-img-editor-preloader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    min-width: 150px;
    width: 150px;
    height: 70px;
    border: 1px solid #e6e6e6 !important;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px #ddd;
  }
  
  .s3-img-editor-preloader progress {
    width: calc(100% - 20px);
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .s3-img-editor-preloader.job-edit-img-preloader {
    width: 100px;
    height: 100px;
    margin: 20px 20px 0px 0px;
  }
  
  .s3-img-editor {
    position: relative !important;
    width: 100px !important;
    height: 70px;
  }
  
  .s3-img-file {
    width: 100px;
    height: 70px;
    object-fit: cover;
    object-position: top;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 3px 2px #ddd;
    border-radius: 5px;
  }
  
  .s3-img-editor-file {
    position: relative !important;
    width: 150px;
    height: 70px;
  }
  
  .s3-img-editor-file-icon-name {
    height: 70px;
    width: 150px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 3px 2px #ddd;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    margin-bottom: 0px !important;
  }
  
  .s3-img-editor-file-icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
  }
  
  .s3-img-editor-name-txt {
    color: #4b4b52 !important;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px !important;
    padding: 5px;
    /* overflow: auto !important; */
    cursor: context-menu;
  }
  
  .s3-img-editor-name-txt::-webkit-scrollbar {
    display: none;
  }
  
  .s3-file-remove-btn {
    position: absolute !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0px !important;
    top: -5px;
    right: -5px;
    font-size: 10px !important;
    cursor: pointer !important;
    color: #000 !important;
    background: #fff !important;
    border-radius: 50% !important;
    border: 2px solid #000 !important;
    z-index: 1 !important;
  }
  
  .s3-img-editor:hover .s3-file-remove-btn {
    background: #000 !important;
    color: #fff !important;
    border: 2px solid #fff !important;
  }
  
  .s3-img-editor-file:hover .s3-file-remove-btn {
    background: #000 !important;
    color: #fff !important;
    border: 2px solid #fff !important;
  }