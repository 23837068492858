.custom-btn{
    font-family: var(--primary-font) !important;
    position: relative;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all linear 0.3s;
    cursor: pointer;
    column-gap: 13px;
}

.custom-btn, .custom-btn span {
    font-family: var(--primary-font) !important;
}

.custom-btn__regular{
    width: max-content;
    height: 42px !important;
    padding: 8.5px 16px 12.5px !important;
    border-radius: 7.5px !important;
    font-size: 16px !important;
    line-height: 21px !important;
}

.custom-btn__small{
    width: max-content;
    min-height: 32px;
    font-size: var(--p3-font-size) !important;
    line-height: var(--p3-line-height) !important;
    padding: 6px 13.5px 9px !important;
    border-radius: 4.5px !important;
}

.custom-btn__long{
    height: 42px !important;
    width: 100%;
    padding: 8.5px 16px 12.5px !important;
    border-radius: 7.5px !important;
    font-size: 16px !important;
    line-height: 21px !important;
}

.custom-btn__big{
    height: 60px !important;
    width: max-content;
    padding: 12px 24px !important;
    border-radius: 7.5px !important;
    font-size: 20px !important;
    line-height: 26px !important;
}

.custom-btn__no-style{
    box-shadow: none !important;
    border: none !important;
    z-index: 1;
    background: transparent !important;
    font-family: var(--primary-font-family__semibold) !important;
    font-size: 16px !important;
    line-height: 21px !important;
}

.custom-btn span{
    display: block;
    margin: 0px !important;
    font-family: var(--primary-font-family__semibold) !important;
}

.custom-btn__regular-skeleton{
    min-width: 150px;
}

/************* primary btn style ***********/

.btn__solid-primary{
    background: var(--primary-color) !important;
    color: var(--white-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.btn__solid-primary:hover{
    background: var(--primary-color__hover) !important;
    color: var(--white-color) !important;
}

.btn__solid-primary__disabled{
    background: var(--primary-color__disable) !important;
    color: rgba(255, 255, 255, 0.5) !important;
    border: 1px solid var(--primary-color__disable) !important;
}

.btn__no-outline-primary{
    background: var(--primary-color) !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    box-shadow: none !important;
}

.btn__no-outline-primary:hover{
    background: var(--black-color) !important;
    color: var(--white-color) !important;
    border: 1px solid var(--black-color) !important;
}

.btn__no-outline-primary__disable{
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border: 1px solid #d9d9d9 !important;
}

/******secondary button style ***********/

.btn__outline-secondary {
    background: var(--white-color) !important;
    color: var(--black-color) !important;
    border: 1px solid var(--secondary-color__border) !important;
}

.btn__outline-secondary:hover{
    background: rgba(0, 0, 0, 0.05) !important;
}

.btn__outline-secondary__disable {
    background: var(--white-color) !important;
    color: var(--black-color) !important;
    border: 1px solid var(--black-color) !important;
    opacity: 0.2;
}

.btn__no-outline-secondary{
    background: transparent !important;
    color: var(--white-color) !important;
    border: 1px solid var(--white-color) !important;
    box-shadow: none !important;
}

.btn__no-outline-secondary:hover{
    background: var(--white-color) !important;
    color: var(--black-color) !important;
    border: 1px solid var(--white-color) !important;
}

.btn__no-outline-secondary__disable{
    color: var(--black-color) !important;
    border: 1px solid var(--black-color) !important;
    opacity: 0.2;
}

.btn__no-outline-secondary:hover svg{
    filter: invert(1);
}

.btn__no-bg-outline-secondary{
    background: transparent !important;
    color: var(--black-color) !important;
    border: 1px solid var(--black-color) !important;
    box-shadow: none !important;
}

.btn__no-bg-outline-secondary:hover{
    color: var(--black-color) !important;
    border: 1px solid var(--black-color) !important;
    opacity: 0.2;
}

/*********** tertiary button style ***********/

.btn__solid-tertiary{
    background: var(--tertiary-color) !important;
    color: var(--black-color) !important;
    border: 1px solid var(--tertiary-color) !important;
}

.btn__solid-tertiary:hover{
    background: var(--tertiary-color) !important;
    color: var(--black-color) !important;
    opacity: 0.6;
}

.btn__solid-tertiary__disable{
    background: var(--ternary-color__disable) !important;
    color: rgba(0, 0, 0, 0.5) !important;
    opacity: 0.5;
}

/************** custom button *******/

.btn__solid-white-transparent{
    background: transparent !important;
    color: var(--white-color) !important;
    border: 1px solid var(--white-color) !important;
}   