/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.invoice-data-column-div{
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px #ddd;
}

.invoice-data-value{
  font-weight: 600;
}

.invoice-data-column-div p{
  display: flex;
  justify-content: space-between;
}

.invoice-data-column-div .invoice-title-text{
  font-weight: 900;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.project-currency-select .ant-select-selection-selected-value .countryNameTxt, .project-currency-select-dropdown .countryNameTxt{ 
  display: none;
}

.notes__wrapper .ant-tooltip-content {
  height: 100%;
  max-height: 350px;
  overflow: hidden auto;
}

.notes__wrapper .ant-tooltip-inner {
  white-space: pre-line;
}

.export-action-btn-wrap{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.export-action-btn-wrap button:last-child{
  margin-left: 20px;
}

.custom-modal-style .ant-modal-content{
  border-radius: 8px;
  overflow: hidden;
}

.ant-modal-close, .ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px !important;
  right: 0px !important;
  width: 56px !important;
  height: 56px !important;
  background: transparent !important;
}

.modal-loading-wrap {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-loader-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: calc(100vh - 65px);
}

.loading__anim {
  width: 45px;
  height: 45px;
  border: 4px solid rgba(189, 189, 189, 0.25);
  border-left-color: var(--primary-color);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  animation: rotate 0.7s infinite linear;
}
@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

.custom-table-style.ant-table-wrapper {
  background: #fafafa;
  height: 100%;
}

.seller-reject-badge {
  background: var(--red-color);
  border-radius: 7.5px;
  color: var(--white-color);
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 2px 10px;
}

.custom-btn.hide-export-btn {
  display: none !important;
}

.custom-export-all-btn {
  width: 150px !important;
}

.custom-export-spinner .anticon {
  color: #fff;
}
.ant-switch {
  border-radius: 20px;
}

.seller-inactive-status{
  background-color: #faf014;
  padding: 3px 6px;
  margin-bottom: 10px;
  display: block;
  width: max-content;
}

.seller-deleted-status{
  background-color: #ff2e2e;
  color:#fff;
  padding: 3px 6px;
  margin-bottom: 10px;
  display: block;
  width: max-content;
}

.seller-role-action-btn .ant-switch {
  background: rgba(255, 0, 0, 0.2)
}

.seller-role-action-btn .ant-switch-checked {
  background: var(--red-color);
}

.custom-dropdown-style {
  background: #fff;
  overflow: hidden;
  box-shadow: 0 12px 26px rgba(0,0,0,.1607843137254902);
}

.custom-dropdown-style.link__dropdown  .ant-dropdown-menu-item{
  padding: 7px 12px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.custom-dropdown-style.link__dropdown div:not(:last-child) {
  border-bottom: 0.5px solid #676c72;
}

/********size chart style******/
.size-type__table .ant-table-thead .ant-table-cell{
  font-family: var(--primary-font-family__semibold);
  background-color: var(--storm-color) !important;
}
.size-type__table .ant-table-tbody tr td:first-child{
  font-family: var(--primary-font-family__bold);
}

.isoComponentTitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #788195;
}

/*** conversation offer division ***/

.conversation-project-details {
  background: #eff6f6;
  border: 1px solid var(--black-color);
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.conversation-project-details > div:first-child {
  background: var(--black-color);
  padding: 10px 20px;
}

.conversation-project-details > div:first-child p {
  color: var(--white-color);
}

.conversation-project-details > div:last-child {
  padding: 20px;
}

/* BAA Assistant buttons start */
.baa-conversation-buttons {
  width: 300px;
  max-width: 100%;
  display: inline-block;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 5px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  /* box-shadow: 0 8px 8px rgba(0, 0, 0.15, 0.15); */
  /* border: 0.1px solid; */
  overflow: hidden;
}

.custom-baa-assistant-btn {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.custom-baa-assistant-btn:last-child {
  border-bottom: none; 
} 

.baa-assistant-button-link {
  text-decoration: none;
  color: #000000;
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border: none;
  box-shadow: none;
}

.baa-assistant-button-text {
  flex: 1;
  text-align: left;
  margin-right: 8px;
  white-space: normal;
  word-wrap: break-word;
}

.baa-assistant-button-icon {
  margin-left: auto;
}

@media (max-width: 767px) {
  .baa-conversation-buttons {
      width: 100%;
  }
}

/* BAA Assistant buttons end */

.system-notification-wrap{
  width: max-content;
  max-width: 90%;
  margin: 20px auto;
}

.system-notification-sub-wrap{
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr;
  background: #f6f7f7;
  border-radius: 20px;
  padding: 10px 20px;
}

.system-notification-wrap p{
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #676c72;
  margin: 0px 0px 0px 10px;
}

/*** file attachment css ***/
.job-attachment-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 20px;
  margin: 20px 0px;
}

.job-attachment-container .document-file-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #919191;
  box-shadow: 0px 0px 5px 2px #ddd;
}

.job-attachment-container .loading-file-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #919191;
  box-shadow: 0px 0px 5px 2px #ddd;
}

.job-attachment-container .document-img-type {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px #ddd;
}

.job-attachment-container a[href*="heic"]::before, .job-attachment-container a[href*="HEIC"]::before {
  content: url('https://api.iconify.design/ant-design/file-image-filled.svg?color=gray&width=40&height=40');
}

.job-attachment-container a[href*="pdf"]::before {
  content: url('https://api.iconify.design/ant-design/file-pdf-filled.svg?color=red&width=40&height=40');
}

.job-attachment-container a[href*="doc"]::before, .job-attachment-container a[href*="docx"]::before {
  content: url("https://api.iconify.design/ant-design:file-word-outlined.svg?color=%231753b3&width=40&height=40");
}

.job-attachment-container a[href*="xls"]::before, .job-attachment-container a[href*="xlsx"]::before {
  content: url('https://api.iconify.design/ant-design/file-excel-filled.svg?color=%231d6f42&width=40&height=40');
}

.job-attachment-container a[href*="ppt"]::before,.job-attachment-container a[href*="pptx"]::before {
  content: url('https://api.iconify.design/ant-design/file-ppt-filled.svg?color=%23d04423&width=40&height=40');
}

.job-attachment-container a[href*="txt"]::before {
  content: url('https://api.iconify.design/ant-design/file-text-filled.svg?color=%23ddd&width=40&height=40');
}

.job-attachment-container .document-video-type {
  content: url('https://api.iconify.design/vscode-icons/file-type-video.svg?width=40&height=40');
}

.job-attachment-container .document-unsupported-type {
  content: url('https://api.iconify.design/ant-design/file-unknown-filled.svg?color=%23ddd&width=40&height=40');
}

.attachmentWrapper{
  position: relative;
}

.attachmentWrapper .attachmentRemove{
  position: absolute;
  top: 5px;
  right: 5px;
  background: #0009;
  width: 18px;
  border-radius: 100%;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.subscription-badge {
  background: red;
  border-radius: 7.5px;
  color: var(--white-color);
  width: max-content;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 2px 10px;
}
.subscription-badge.yellow {
  background: rgb(247, 255, 6);
  border-radius: 7.5px;
  color: var(--black-color);
  width: max-content;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 2px 10px;
}

.subscription-badge.active {
  background: var(--green-color);
}

.otp-order-counter {
  background: red;
  border-radius: 7.5px;
  color: var(--white-color);
  width: max-content;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 2px 10px;
}

.otp-order-counter.active {
  background: var(--sungold-color);
}

.seller-visible {
  background: red;
  border-radius: 7.5px;
  color: var(--white-color);
  width: max-content;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 2px 10px;
}

.seller-visible.active {
  background: var(--green-color);
}


.announcement_popover{
  width: 30vw;
  max-height: 80vh;
  overflow-y: auto;
}

.call-icon{
  width: 18px;
  height: 18px;
  background: var(--green-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}